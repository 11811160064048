require("utils/ring_to_endpoint");
import {Common} from "utils/common";
console.log(Common)
window.Common = Common;

// This does not really have to be added to the Window as only js files access it. However does need the be same instance everytime.
import {DataBinder} from "utils/data_binder" 
window.DataBinder = DataBinder;

require('reporting/user_reports')

import "../reporting/audio_player";
import '../admin/campaigns/regions_tree';
import '../ai_leadscore/ai_score';

