import ReactDOM from 'react-dom';
import * as React from 'react';
import CallProcessingPlayer from '../call_rescoring/CallProcessingPlayer';
import { Common } from '../utils/common';

class AiScore extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    ReactDOM.render(<AiScoreReact />, this);
  }
}

customElements.define('ai-score', AiScore);

/**
 * @typedef {Object} AiScoreData
 * @property {number} ai_call_details_id
 * @property {AiResponse} ai_response
 * @property {string} audiourl
 * @property {string} callid
 * @property {string} transcription
 * @property {boolean} isSaved
 */

/**
 * @typedef {Object} ScoreResult
 * @property {string} call_status_text
 * @property {string} call_type_text
 */

/**
 * @typedef {Object} AiResponse
 * @property {ScoreResult} llamabot_score
 * @property {ScoreResult} original_score
 */

const itemsPerPage = 10;

function AiScoreReact() {
  const [callDate, setCallDate] = React.useState('');
  const [data, setData] = React.useState(/** @type {AiScoreData[]} */ ([]));
  const [currentPage, setCurrentPage] = React.useState(1);
  // Form submission increments the counter which is a dependency of the useEffect,
  // causing it to refetch even if the date is the same.
  const [fetchTrigger, setFetchTrigger] = React.useReducer((c) => c + 1, 0);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = Math.min(startIdx + itemsPerPage, data.length);
  const currentItems = data.slice(startIdx, endIdx);

  function getPageNumbers() {
    const maxButtons = 5;
    const half = Math.floor(maxButtons / 2);
    let start = Math.max(1, currentPage - half);
    let end = Math.min(totalPages, start + maxButtons - 1);

    if (end === totalPages) {
      start = Math.max(1, end - maxButtons + 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }

  React.useEffect(() => {
    if (!callDate) {
      setData([]);
      return;
    }

    setIsLoading(true);

    fetch(`/ai_leadscore/ai_score/data?date=${callDate}`)
      .then((response) => response.json())
      .then((data) => {
        const parsedData = data.map((item) => {
          const ai_response = JSON.parse(item.ai_response);
          return { ...item, ai_response, isSaved: false };
        });
        setData(parsedData);
      })
      .catch((error) => {
        console.error('Error loading JSON:', error);
        Common.notify('Failed to fetch data', 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [callDate, fetchTrigger]);

  async function saveScore(e) {
    e.preventDefault();

    const formData = {
      ai_call_details_id: e.target.elements.ai_call_details_id.value,
      qa_call_type_result: e.target.elements.callType.value,
      qa_call_status_result: e.target.elements.callStatus.value,
      notes: e.target.elements.notes.value,
    };

    setIsSaving(true);

    try {
      const res = await fetch('/ai_leadscore/ai_score', {
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(formData),
      });

      if (res.ok) {
        // mark item as saved
        const newData = data.map((item) => {
          if (item.ai_call_details_id == e.target.elements.ai_call_details_id.value) {
            return { ...item, isSaved: true };
          } else {
            return item;
          }
        });
        setData(newData);
      } else {
        throw new Error('Failed to save score');
      }
    } catch (error) {
      Common.notify('Failed to save score', 'error');
    } finally {
      setIsSaving(false);
    }
  }

  /**
   * @param {string} transcription
   */
  function formatTranscription(transcription) {
    try {
      const parsedData = JSON.parse(transcription);

      if (Array.isArray(parsedData)) {
        // If the API returned an array of objects, format accordingly
        return parsedData.map((entry, index) => {
          const [[speaker, text]] = Object.entries(entry);
          return (
            <p key={index}>
              {speaker}: {text}
            </p>
          );
        });
      }
    } catch (e) {
      // If parsing fails, assume it's a plain string format and process it normally
    }

    // Handle the plain text format with `\n`-separated dialogue
    return transcription.split('\n').map((line, index) => <p key={index}>{line}</p>);
  }

  return (
    <div
      style={{
        paddingRight: 15,
        paddingLeft: 15,
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 1280,
      }}
    >
      <h1>AI Scores</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setCallDate(e.target['callDate'].value);
          setFetchTrigger();
        }}
        className='form-inline'
        style={{ marginBottom: 25 }}
      >
        <div className='form-group'>
          <label htmlFor='callDate' style={{ marginRight: 5 }}>
            Call Date
          </label>
          <input
            style={{ maxWidth: 200, marginRight: 5 }}
            type='date'
            className='form-control'
            id='callDate'
            name='callDate'
          />
        </div>
        <button type='submit' className='btn btn-primary'>
          Search
        </button>
      </form>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className='divide-y'>
            {currentItems.map((item) => {
              return (
                <div className='row' style={{ marginBottom: 25 }} key={item.callid}>
                  <div className='col-xs-12'>
                    <h5>
                      <strong>Call ID:</strong> {item.callid}
                    </h5>
                  </div>
                  <div className='col-xs-12' style={{ marginBottom: 15 }}>
                    <CallProcessingPlayer url={item.audiourl} />
                  </div>
                  <div className='col-xs-12 col-md-6'>
                    <div className='panel panel-default'>
                      <div className='panel-heading'>Transcription</div>
                      <div className='panel-body' style={{ maxHeight: 400, overflow: 'auto' }}>
                        {formatTranscription(item.transcription)}
                      </div>
                    </div>
                  </div>
                  <form onSubmit={saveScore}>
                    <input
                      type='hidden'
                      name='ai_call_details_id'
                      value={item.ai_call_details_id}
                    />
                    <div className='col-xs-12 col-md-6'>
                      <div className='row'>
                        <div className='col-xs-12 col-md-6'>
                          <div>
                            <h4>Call Type</h4>
                            <div className='well'>
                              <p>
                                <strong>Human Classification:</strong>{' '}
                                {item.ai_response.original_score.call_type_text}
                              </p>
                              <p>
                                <strong>AI Classification:</strong>{' '}
                                {item.ai_response.llamabot_score.call_type_text}
                              </p>
                            </div>
                          </div>
                          <div>
                            <label>Which classification is more correct?</label>
                            <div className='radio'>
                              <label>
                                <input
                                  disabled={item.isSaved}
                                  type='radio'
                                  name='callType'
                                  value={1}
                                  required
                                />{' '}
                                {item.ai_response.original_score.call_type_text}
                              </label>
                            </div>
                            <div className='radio'>
                              <label>
                                <input
                                  disabled={item.isSaved}
                                  type='radio'
                                  name='callType'
                                  value={0}
                                />{' '}
                                {item.ai_response.llamabot_score.call_type_text}
                              </label>
                            </div>
                            <div className='radio'>
                              <label>
                                <input
                                  disabled={item.isSaved}
                                  type='radio'
                                  name='callType'
                                  value={2}
                                />{' '}
                                Both
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className='col-xs-12 col-md-6'>
                          <div>
                            <h4>Call Status</h4>
                            <div className='well'>
                              <p>
                                <strong>Human Classification:</strong>{' '}
                                {item.ai_response.original_score.call_status_text}
                              </p>
                              <p>
                                <strong>AI Classification:</strong>{' '}
                                {item.ai_response.llamabot_score.call_status_text}
                              </p>
                            </div>
                          </div>
                          <div>
                            <label>Which classification is more correct?</label>
                            <div className='radio'>
                              <label>
                                <input
                                  disabled={item.isSaved}
                                  type='radio'
                                  name='callStatus'
                                  value={1}
                                  required
                                />{' '}
                                {item.ai_response.original_score.call_status_text}
                              </label>
                            </div>
                            <div className='radio'>
                              <label>
                                <input
                                  disabled={item.isSaved}
                                  type='radio'
                                  name='callStatus'
                                  value={0}
                                />{' '}
                                {item.ai_response.llamabot_score.call_status_text}
                              </label>
                            </div>
                            <div className='radio'>
                              <label>
                                <input
                                  disabled={item.isSaved}
                                  type='radio'
                                  name='callStatus'
                                  value={2}
                                />{' '}
                                Both
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xs-12' style={{ marginBottom: 15 }}>
                      <div className='form-group'>
                        <label htmlFor='notes'>Notes</label>
                        <textarea
                          disabled={item.isSaved}
                          name='notes'
                          id='notes'
                          className='form-control'
                        ></textarea>
                      </div>
                    </div>
                    <div className='col-xs-12'>
                      {item.isSaved ? (
                        <div className='alert alert-success'>Saved</div>
                      ) : (
                        <button
                          type='submit'
                          className='btn btn-success pull-right'
                          disabled={isSaving}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              );
            })}
          </div>
          {data.length > 0 ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: 20,
              }}
            >
              <p>
                Showing {startIdx + 1} to {endIdx} of {data.length} results.
              </p>
              <nav aria-label='Page navigation'>
                <ul className='pagination'>
                  <li className={`${currentPage === 1 ? 'disabled' : ''}`}>
                    <a
                      href='#'
                      aria-label='Previous'
                      onClick={() => setCurrentPage((p) => Math.max(p - 1, 1))}
                    >
                      <span aria-hidden='true'>&laquo;</span>
                    </a>
                  </li>
                  {getPageNumbers().map((num) => {
                    return (
                      <li
                        key={num}
                        className={num === currentPage ? 'active' : ''}
                        onClick={() => setCurrentPage(num)}
                      >
                        <a href='#'>{num}</a>
                      </li>
                    );
                  })}
                  <li className={`${currentPage === totalPages ? 'disabled' : ''}`}>
                    <a
                      href='#'
                      aria-label='Next'
                      onClick={() => setCurrentPage((p) => Math.min(p + 1, totalPages))}
                    >
                      <span aria-hidden='true'>&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
