import React, { useState, useEffect, useRef, useMemo } from 'react';
import WaveSurfer from 'wavesurfer.js';
import Timeline from 'wavesurfer.js/dist/plugins/timeline';
import Hover from 'wavesurfer.js/dist/plugins/hover';

/**
 * Custom hook that initializes and returns a WaveSurfer instance
 * @param {import('react').RefObject<HTMLElement>} containerRef - React ref to the container element
 * @param {Omit<import('wavesurfer.js').WaveSurferOptions, 'container'>} options - WaveSurfer options: https://wavesurfer-js.org/docs/types/wavesurfer.WaveSurferOptions
 * @returns {WaveSurfer | null}
 */
function useWavesurfer(containerRef, options) {
  const [wavesurfer, setWavesurfer] = useState(/** @type {WaveSurfer | null} */ (null));

  // Initialize wavesurfer when the container mounts
  // or any of the props change
  useEffect(() => {
    if (!containerRef.current) return;

    const ws = WaveSurfer.create({
      ...options,
      container: containerRef.current,
    });

    setWavesurfer(ws);

    return () => {
      ws.destroy();
    };
  }, [options, containerRef]);

  return wavesurfer;
}

/**
 * @param {{ url: string }} props
 */
export default function CallProcessingPlayer({ url }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const containerRef = useRef(null);

  // Options must be memoized or they will be recreated on every render, triggering
  // wavesurfer to reinitialize infinitely.
  const wavesurferOptions = useMemo(() => {
    return {
      url,
      height: 80,
      waveColor: 'blue',
      normalize: true,
      audioRate: 1,
      progressColor: 'purple',
      cursorColor: 'navy',
      plugins: [
        Hover.create({
          labelBackground: '#000',
          labelColor: '#fff',
          labelSize: 12,
        }),
        Timeline.create({ primaryLabelInterval: 5 }),
      ],
    };
  }, [url]);

  const wavesurfer = useWavesurfer(containerRef, wavesurferOptions);

  useEffect(() => {
    if (!wavesurfer) return;

    setIsPlaying(false);

    const subscriptions = [
      wavesurfer.on('play', () => setIsPlaying(true)),
      wavesurfer.on('pause', () => setIsPlaying(false)),
      wavesurfer.on('ready', () => setIsReady(true)),
    ];

    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [wavesurfer]);

  return (
    <div>
      <div style={{ border: '1px solid black' }} ref={containerRef}></div>
      <div
        className='form-inline'
        style={{ display: 'flex', justifyContent: 'center', gap: 4, marginTop: 8 }}
      >
        <button
          className='btn btn-default'
          disabled={!isReady}
          onClick={() => wavesurfer?.skip(-3)}
        >
          <i className='fa fa-step-backward' /> Backward
        </button>
        <button
          className='btn btn-default'
          disabled={!isReady}
          onClick={() => wavesurfer?.playPause()}
        >
          {isPlaying ? (
            <>
              <i className='glyphicon glyphicon-pause' /> Pause
            </>
          ) : (
            <>
              <i className='glyphicon glyphicon-play' /> Play
            </>
          )}
        </button>
        <button className='btn btn-default' disabled={!isReady} onClick={() => wavesurfer?.skip(3)}>
          <i className='fa fa-step-forward' /> Forward
        </button>
        <select
          onChange={(event) => {
            const playbackRate = Number(event.target.value);
            wavesurfer?.setPlaybackRate(playbackRate);
          }}
          className='form-control'
        >
          <option value='0.5'>0.5 speed</option>
          <option value='1'>Normal speed</option>
          <option value='2'>2x speed</option>
          <option value='3'>3x speed</option>
          <option value='4'>4x speed</option>
        </select>
      </div>
    </div>
  );
}
